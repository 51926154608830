.checkout-title {
  display: flex;
}

.checkout-title button {
  border: none;
  background-color: #ffffff;
  color: #4561e1;
  font-size: large;
}

.checkout-title button:hover {
  color: #c5c7ce;
}

.total {
  display: flex;
  justify-content: space-evenly;
  background: #f5f5f5;
  padding: 2rem;
  text-decoration: none;
}

.items-number {
  padding: 0rem 2rem;
}

.shopping {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.trash {
  border: none;
  background-color: #f5f5f5;
  transition: 0.5s;
}

.trash:hover {
  background-color: #dbdbdb;
}

.comment {
  padding: 2rem 0rem;
  display: flex;
  flex-direction: column;
}

.comment textarea {
  height: 275px;
  background-color: #f5f5f5;
  border: none;
  margin: 1rem;
  padding: 0;
}

.checkout-form {
  background-color: #f5f5f5;
  padding: 2rem;
}

.checkout-list li {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style-type: none;
  padding: 1rem;
  margin: 0;
}

.checkout-button {
  padding: 1rem;
  border: none;
  border-radius: 10px;
  background: #3f4957;
  color: white;
  width: 90%;
  align-self: center;
  transition: 0.5s;
}

.checkout-button:hover {
  background-color: #637386;
}

.checkout-button-container {
  display: flex;
  justify-content: center;
}

.checkout-input {
  width: 100%;
  margin: auto;
}

.checkout-input input {
  border: none;
  border-radius: 10px;
  height: 50px;
  margin: 0.5rem;
  width: 80%;
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}

.modal {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: black; /* Fallback color */
}
.modal-button {
  padding: 1rem;
  border: none;
  border-radius: 10px;
  background: #3f4957;
  color: white;
  width: 100%;
  align-self: center;
  transition: 0.5s;
}

.modal-button:hover {
  background-color: #637386;
}
