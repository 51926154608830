.styledButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  margin: 5px 0;
  font-size: 15px;
  border-radius: 8px;
  border: 0;
  background: #4561e1;
  font-family: DM Sans;
  color: white;
  cursor: pointer;
  resize: none;
}
