@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

html {
  font-family: 'DM Sans', sans-serif !important;
}

body {
  font-style: normal;
  font-size: 15px;
}

i {
  font-style: normal !important;
}

.content {
  background-color: #f5f5f5;
  min-height: 100vh;
  height: auto;
  padding: 2rem;
}

.greeting {
  justify-content: center;
  background-color: #ffffff;
  padding: 4rem;
  margin: 0 12rem;
}

h1 {
  font-family: 'DM Sans';
  font-style: normal;
  font-size: 30px;
  font-weight: bold;
}

h2 {
  font-size: 20px;
  font-weight: normal;
  font-family: 'DM Sans';
}

.schedule {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  padding: 4rem;
  margin: 2rem 12rem;
}

.content select {
  background: #ffffff;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.avail-days {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.day {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #c20d0d;
  border-left: 1px solid #c4c4c4;
  border-right: 1px solid #c4c4c4;
  box-sizing: border-box;
  color: #3f4957;
  padding: 1rem;
  margin: 1rem 0;
}

.day ::checked {
  color: #5551ff;
}
