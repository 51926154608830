/* For mobile phones: */
[class*="col-"] {
  width: 100%;
}

.custom-paper {
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 15px !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
  display: inline-block;
  overflow: hidden;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 5px;
  height: 54px;
  color: white;
  background: #445CEC;
  border-radius: 15px 15px 0px 0px;
  font-size: 28px;
}

.dashboard-container {
  display: flex;
  height: calc(100vh - 64px - 24px - 24px - 32px - 16px);
}

.dashboard-column {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 10px;

    .column-item-1 {
      height: 50%;
      margin-bottom: 10px;
    }
    .column-item-2 {
      height: 50%;
    }
}

.dashboard-tasks-container {
  width: 70%;
  min-width: 600px;
  min-height: 200px; 
}

.dashboard-colleges-container {
  width: 95%;
  min-width: 300px; 
  min-height: 200px; 
  position: relative;

  .colleges-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 5px;
    height: 54px;
    color: white;
    background: #445cec;
    border-radius: 15px 15px 0 0;
    font-size: 28px;
  }

  .colleges-status {
    display: grid;
    place-items: center;
    align-content: center;
    justify-content: center;
    height: 100%;

    .colleges-item {
      display: flex;
      justify-content: center;
      align-items: center;
      color: '#C1C9F9';
      align-items: center;
    }

    .colleges-text {
      font-size: 40px;
    }
  }
}

.graph-container {
  padding-top: 40px;
}


.dashboard-tests-container {
  width: 95%;
  min-width: 300px;
  min-height: 200px;

  .recharts-wrapper {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .recharts-container {
    width: 100%;
    height: 100%;
  }

  .recharts-graph {
    margin-top: 1;
    
  }
}

@media (max-width: 1535px) {
  .dashboard-tasks-container,
  .dashboard-colleges-container,
  .dashboard-tests-container {
    .colleges-container .colleges-text {
      font-size: 40px !important;
    }

    .recharts-graph {
      margin-top: 0;
    }
  }
}