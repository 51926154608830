.blur {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.invisible {
  display: none;
}

.visible {
  text-align: center;
  visibility: visible;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.thread-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.thread {
  border-top: 0.1px solid gray;
}
